<template>
  <div class="mb-3">
    <CCard>
      <CCardBody>
        <div class="d-flex pb-3 desktop-view">
          <span>
            <span class="text-primary desktop-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
            <span class="text-primary d-md-block d-none desktop-view h6">
              <!-- Candidate {{ activityDetails.candidate_uid }} : -->
              Candidate {{ activityDetails.candidate_display_uid }} :
              {{ activityDetails.candidate.full_name }}
            </span>
          </span>
          <span class="h5 text-primary m-auto">Select Interview Dates</span>
          <div class="d-flex ml-auto mr-0">
            <span class="px-2 text-primary desktop-view h6">
              <span
                >Created : {{ activityDetails.created_on | formatDate }}</span
              >
              <span class="d-block"
                ><span class="pr-1">Days Left : </span
                >{{ activityDetails.display_text_days }}
              </span>
              <span class="d-flex" style="width: 185px"
                ><div class="pr-1 text-nowrap">By :</div>
                <div class="text-wrap">
                  {{ activityDetails.initiated_by_user | roles }}
                </div>
              </span>
            </span>
          </div>
        </div>

        <div class="pb-3 mobile-view">
          <span>
            <span class="text-primary mobile-view h6">
              <!-- Job {{ activityDetails.job_id }} : -->
              Job {{ activityDetails.job_display_uid }} :
              {{ activityDetails.job.job_title }}
            </span>
            <span class="text-primary mobile-view h6 d-block">
              <!-- Candidate {{ activityDetails.candidate_uid }} : -->
              Candidate {{ activityDetails.candidate_display_uid }} :
              {{ activityDetails.candidate.full_name }}
            </span>
          </span>
          <div class="d-flex ml-auto mr-0">
            <span class="text-primary font-weight-bold mobile-view">
              <span
                >Created :{{ activityDetails.created_on | formatDate }}</span
              >
              <span class="d-block"
                ><span class="font-weight-lighter pr-1 mobile-view"
                  >Days Left:</span
                >{{ activityDetails.display_text_days }}
              </span>
              <span class="d-block"
                ><span class="font-weight-lighter pr-1 mobile-view">By:</span
                >{{ activityDetails.initiated_by_user | roles }}
              </span>
            </span>
          </div>
        </div>

        <div class="pt-2">
          <CTabs variant="tabs" :active-tab="0">
            <CTab title="Schedule">
              <div class="mt-2">
                <CRow>
                  <CCol md="5 ">
                    <div class="pb-2">
                      <CTabs
                        variant="tabs"
                        :active-tab="0"
                        @update:activeTab="changeActiveTab"
                      >
                        <CTab title="Candidate">
                          <div class="mt-1 mb-2">
                            <CButton
                              type="button"
                              name="candidate-view-documents"
                              class="mx-1 py-1 mt-1 btn-primary"
                              @click="showDocuments = !showDocuments"
                              size="sm"
                              >View Documents</CButton
                            >
                            <CButton
                              type="button"
                              name="candidate-view-profile"
                              class="mx-1 py-1 mt-1 btn-primary"
                              @click="
                                navToCandidateDetails(
                                  activityDetails.candidate_uid
                                )
                              "
                              size="sm"
                              >View Profile</CButton
                            >
                            <CButton
                              type="button"
                              name="candidate-view-frontsheets"
                              class="mx-1 py-1 mt-1 btn-primary"
                              @click="openFrontSheet"
                              size="sm"
                            >
                              Frontsheet
                              <i class="fas fa-download ml-2"></i>
                            </CButton>
                          </div>
                        </CTab>
                        <CTab title="Job">
                          <div class="mt-1 mb-2">
                            <CButton
                              type="button"
                              name="job-preview-adert"
                              class="mx-1 py-1 mt-1 btn-primary"
                              @click="openJobAdvert(activityDetails.job_id)"
                              size="sm"
                              >Preview Advert</CButton
                            >
                            <CButton
                              type="button"
                              name="job-details"
                              class="mx-1 py-1 mt-1 btn-primary"
                              @click="navToJobDetails(activityDetails.job_id)"
                              size="sm"
                              >Job Details</CButton
                            >
                          </div>
                        </CTab>
                      </CTabs>
                    </div>
                    <div
                      v-if="showDocuments && activeTab === 0"
                      class="
                        d-flex
                        align-items-center
                        documentScrolling
                        document-table
                        border
                        mb-2
                      "
                    >
                      <table class="w-100 table">
                        <thead>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Download</th>
                          <th>Preview</th>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(document, index) in documents"
                            :key="index"
                          >
                            <td>{{ document.lable }}</td>
                            <td>{{ document.name }}</td>
                            <td>
                              <CButton
                                type="button"
                                @click="fileDownload(document)"
                              >
                                <i class="fas fa-download"></i>
                              </CButton>
                            </td>
                            <td>
                              <CButton type="button" @click="previewCV(document)">
                                <i class="fas fa-eye"></i>
                              </CButton>
                            </td>
                          </tr>
                          <tr v-if="documents.length === 0">
                            <td colspan="4">
                              <h6 class="text-center text-muted">
                                No Documents to Preview
                              </h6>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="mx-3">
                      <CRow class="mb-2 mt-2">
                        <CCol xs="2"><h6>Type</h6> </CCol>
                        <CCol xs="10"
                          ><span class="h6 mx-1">:</span
                          >{{
                            activityDetails.type_of_process | convert2Sentence
                          }}
                        </CCol>
                      </CRow>
                      <CRow class="mb-2">
                        <CCol xs="2"><h6>Style</h6> </CCol>
                        <CCol xs="10"
                          ><span class="h6 mx-1">:</span
                          ><span v-html="interviewMode"></span>
                          {{
                            activityDetails.interview_mode | convert2Sentence
                          }}
                        </CCol>
                      </CRow>
                      <CRow class="mb-2">
                        <CCol xs="2"><h6>Time</h6> </CCol>
                        <CCol xs="10"
                          ><span class="h6 mx-1">:</span
                          >{{ activityDetails.alloted_time | convert2Sentence }}
                        </CCol>
                      </CRow>
                    </div>
                  </CCol>
                  <CCol md="7">
                    <h5 class="text-primary">
                      Select Availability - Click Date/Time
                    </h5>
                    <CRow>
                      <CCol md="5">
                        <div class="pt-2 calendar-pad">
                          <div class="calendar-wrapper">
                            <vue-cal
                              class="vuecal--primary-theme"
                              xsmall
                              hide-view-selector
                              :time="false"
                              active-view="month"
                              :disable-views="['week', 'day']"
                              :dblclick-to-navigate="false"
                              style="width: 270px; height: 300px"
                              :events="events"
                              :min-date="new Date()"
                              :selected-date="selectedDate"
                            >
                              <template v-slot:cell-content="{ cell, events }">
                                <div @click="dateClick(cell, events)">
                                  <span class="vuecal__cell-date">{{
                                    cell.content
                                  }}</span>
                                  <span
                                    class="vuecal__cell-events-count"
                                    v-if="events.length"
                                    >{{ events.length }}</span
                                  >
                                </div>
                              </template>
                            </vue-cal>
                          </div>
                        </div>
                      </CCol>
                      <CCol md="7">
                        <div class="m-1">
                          <h6 class="text-primary" v-if="selectedDate">
                            {{ selectedDate | formatDate }}
                          </h6>
                          <CTabs variant="pills" :active-tab="slotTab">
                            <CTab title="Slot 1">
                              <div class="mt-2" v-if="selectedDate">
                                <TimeSlot
                                  name="slot1"
                                  startTime="05:00"
                                  endTime="23:00"
                                  :interval="interval"
                                  :date="selectedDate"
                                  :disabledTime="selectedDateEvents"
                                  :duration="duration"
                                  :slotTimes="slotTime"
                                  @input="handleTimeSlot"
                                />
                              </div>
                            </CTab>
                            <CTab title="Slot 2">
                              <div class="mt-2" v-if="selectedDate">
                                <TimeSlot
                                  name="slot2"
                                  startTime="05:00"
                                  endTime="23:00"
                                  :interval="interval"
                                  :date="selectedDate"
                                  :disabledTime="selectedDateEvents"
                                  :duration="duration"
                                  :slotTimes="slotTime"
                                  @input="handleTimeSlot"
                                />
                              </div>
                            </CTab>
                            <CTab title="Slot 3">
                              <div class="mt-2" v-if="selectedDate">
                                <TimeSlot
                                  name="slot3"
                                  startTime="05:00"
                                  endTime="23:00"
                                  :interval="interval"
                                  :date="selectedDate"
                                  :disabledTime="selectedDateEvents"
                                  :duration="duration"
                                  :slotTimes="slotTime"
                                  @input="handleTimeSlot"
                                />
                              </div>
                            </CTab>
                          </CTabs>
                        </div>
                      </CCol>
                    </CRow>
                    <div class="pt-2" v-if="slotTimeArr.length">
                      <ValidationObserver
                        ref="reviewCandidate"
                        v-slot="{ handleSubmit }"
                      >
                        <form
                          id="reviewCandidate"
                          @submit.prevent="handleSubmit(onSubmit)"
                        >
                          <CRow>
                            <CCol lg="12">
                              <CRow class="mb-3">
                                <label class="col-lg-12 col-md-12"
                                  >Selected Slots</label
                                >
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                  <div
                                    class="item-wrapper"
                                    :key="index"
                                    v-for="(slot, index) of slotTimeArr"
                                  >
                                    <div class="item-container">
                                      <div class="item">
                                        <span class="item-label"
                                          ><div>
                                            {{ slot.name }} -{{
                                              slot.start | formatDate
                                            }}
                                            -{{ slot.start | formatTime }} to
                                            {{ slot.end | formatTime }}
                                          </div></span
                                        >
                                        <span
                                          class="mx-1"
                                          @click="removeSlot(slot)"
                                          ><i class="fas fa-times clear"></i
                                        ></span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </CRow>
                            </CCol>
                          </CRow>
                          <CRow>
                            <CCol lg="12">
                              <CRow class="mb-3">
                                <div class="col-lg-12 col-md-12 d-flex pb-2">
                                  <div>
                                    <label class="text-nowrap pr-3 required"
                                      >Comment</label
                                    >
                                  </div>
                                  <div
                                    class="w-100"
                                    v-if="options['commentsOptions'].length"
                                  >
                                    <Select
                                      name="customComments"
                                      :value="review.customComments"
                                      @input="handleChangeSelectCustom"
                                      :options="
                                        options && options['commentsOptions']
                                          ? options['commentsOptions']
                                          : []
                                      "
                                      :taggable="false"
                                      :multiple="false"
                                      :clearable="false"
                                    />
                                  </div>
                                </div>
                                <!-- <label
                                  class="required col-lg-12 col-md-12 col-sm-12"
                                  >Comment</label
                                > -->
                                <div class="col-lg-12 col-md-12 col-sm-12">
                                  <ValidationProvider
                                    rules="required"
                                    v-slot="{ errors }"
                                  >
                                    <TextareaInput
                                      name="comments"
                                      :value="review.comments"
                                      @input="handleInput"
                                      :error="errors[0]"
                                    />
                                  </ValidationProvider>
                                </div>
                              </CRow>
                            </CCol>
                          </CRow>
                          <div class="text-center mx-0 mt-2 pt-4 pb-4">
                            <CButton
                              type="button"
                              class="action-btn mx-2 btn-outline-primary"
                              @click="navToActivityList"
                              >Exit</CButton
                            >
                            <CButton
                              name="interview-schedule-submit"
                              type="button"
                              class="action-btn mx-2 btn-primary"
                              @click="onSubmit('Hold')"
                              >Submit</CButton
                            >
                          </div>
                        </form>
                      </ValidationObserver>
                    </div>
                  </CCol>
                </CRow>
              </div>
            </CTab>
            <CTab title="Comments">
              <div class="mt-2">
                <CDataTable
                  :striped="true"
                  :bordered="true"
                  :small="true"
                  :items="activityComments"
                  :fields="fields"
                  pagination
                  :items-per-page="5"
                  class="p-2"
                >
                </CDataTable>
              </div>
            </CTab>
          </CTabs>
        </div>
        <div>
          <div class="pt-2"></div>
        </div>
      </CCardBody>
    </CCard>
    <PreviewModal
      :modalTitle="previewModal.modalTitle"
      :modalColor="previewModal.modalColor"
      :isShowPopup="previewModal.isShowPopup"
      :buttons="previewModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="previewModal.isPreview"
      :iframe="iframe"
      :size="previewModal.size"
      :closeOnBackdrop="previewModal.closeOnBackdrop"
    />
    <JobCardPreviewModal
      :isShowPopup="advertModal.isShowPopup"
      :onCancelPreview="advertModalCallBack"
    />
    <FrontSheetModal
      v-if="frontSheetModal.isShowPopup"
      :isShowPopup="frontSheetModal.isShowPopup"
      @modalCallBack="frontSheetModalCallBack"
      :candidateDetail="frontSheetModal.candidate"
      :jobDetail="frontSheetModal.job"
      :frontSheetInfo="frontSheetModal.frontSheetInfo"
      :frontSheets="frontSheetModal.comments"
    />
  </div>
</template>
<script>
import m from "moment";
import { mapActions, mapGetters } from "vuex";
import { getScope, Role, appendAccessToken,generateFullUrlWithToken } from "@/helpers/helper"; 
import Vue from "vue";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TimePicker from "@/components/reusable/Fields/TimePicker";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import pdf from "vue-pdf";
import PreviewModal from "@/components/reusable/PreviewModal";
import { BASE_URL } from "@/service_urls";
import JobCardPreviewModal from "@/components/jobCardPreviewModal";
import TimeSlot from "@/components/reusable/Fields/TimeSlot";
import VueCal from "vue-cal";
import _ from "lodash";
import Select from "@/components/reusable/Fields/Select";
import FrontSheetModal from "@/containers/Frontsheet/FrontSheetModal";
import "vue-cal/dist/vuecal.css";
extend("required", { ...required, message: "This field is required" });
extend("startBefore", {
  params: ["target"],
  validate(value, { target }) {
    if (target) {
      let target_date = new Date(target);
      let input_date = new Date(value);
      if (target_date.getHours() === 0)
        target_date.setDate(target_date.getDate() + 1);
      if (input_date.getHours() === 0)
        input_date.setDate(input_date.getDate() + 1);
      if (input_date > target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given Start Time should be Before End Time!",
});
extend("startAfter", {
  params: ["target"],
  validate(value, { target }) {
    if (target) {
      let target_date = new Date(target);
      let input_date = new Date(value);
      if (target_date.getHours() === 0)
        target_date.setDate(target_date.getDate() + 1);
      if (input_date.getHours() === 0)
        input_date.setDate(input_date.getDate() + 1);
      if (input_date < target_date) {
        return false;
      }
    }
    return true;
  },
  message: "Given End Time should be After Start Time!",
});
export default {
  props: {
    activityDetails: {
      type: Object,
      default: () => null,
    },
    comments: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    TextareaInput,
    DatePicker,
    TimePicker,
    PreviewModal,
    JobCardPreviewModal,
    VueCal,
    TimeSlot,
    Select,
    FrontSheetModal,
  },
  data() {
    return {
      slotTab: 0,
      selectedDate: null,
      activeTab: 0,
      advertModal: {
        isShowPopup: false,
      },
      showDocuments: false,
      review: {},
      payload: {},
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      previewModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["OK"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      slot: {
        slot1: true,
        slot2: false,
        slot3: false,
      },
      fields: [
        { key: "date", _style: "width:25%" },
        {
          key: "name",
          _style: "width:25%",
        },
        {
          key: "role",
          _style: "width:10%",
        },
        {
          key: "status",
          _style: "width:15%",
        },
        {
          key: "comment",
          _style: "width:25%",
        },
      ],
      selectedDateEvents: [],
      slotTime: {},
      viewedDocs: [],
      frontSheetModal: {
        isShowPopup: false,
        candidate: null,
        job: null,
        frontSheetInfo: null,
        comments: [],
      },
      generateFullUrlWithToken,
    };
  },
  computed: {
    ...mapGetters([
      "getUploadedDocument",
      "getReviewedCandidateID",
      "getReviewedJobID",
      "getInterviewCreatedSlots",
      "getInterviewComments",
      "actionComments",
      "candidateProfile",
    ]),
    options() {
      return {
        commentsOptions: this.actionComments || [],
      };
    },
    interval() {
      const mins =
        parseInt(this.activityDetails?.alloted_time.replace(/\D/g, "")) || 30;
      return mins > 30 ? 30 : mins;
    },
    duration() {
      return parseInt(
        this.convert2Sentence(this.activityDetails?.alloted_time)
      );
    },
    activityComments() {
      return (
        this.comments
          // .filter((e) => e.comments)
          .map((v) => ({
            name: v.initiated_by_user_name || "--",
            date: this.formatDate(v.created_on, "Do MMM YYYY") || "--",
            comment: v?.comments || "--",
            role:
              (v?.initiated_by_user?.roles &&
                v?.initiated_by_user?.roles
                  .map((v) => _.startCase(v.role_type))
                  .join()) ||
              "--",
            status:
              `${v?.status} ${v?.sub_status ? " - " + v?.sub_status : ""}` ||
              "--",
          })) || []
      );
    },
    documents() {
      return (
        (this.getUploadedDocument &&
          this.getUploadedDocument.length &&
          this.getUploadedDocument.map(
            ({
              document_type_id,
              uuid_link_to_doc,
              document_name,
              document_type,
              created_on,
              document_ext,
              candidate_document_id,
              comments,
            }) => {
              return {
                link: uuid_link_to_doc,
                lable: document_type.comments,
                name: document_name,
                updatedDate: m(created_on).format("DD-MM-YYYY"),
                document_ext: document_ext?.document_ext,
                document_id: candidate_document_id,
                comments: comments,
                document_type_id: document_type_id,
              };
            }
          )) ||
        []
      );
    },
    slotTimeArr() {
      const keys = Object.keys(this.slotTime);
      const name = { slot1: "Slot 1", slot2: "Slot 2", slot3: "Slot 3" };
      const res = keys.map((key) => {
        const item = this.slotTime[key];
        return {
          key,
          name: name[key],
          ...item,
        };
      });
      return res;
    },
    interviewMode() {
      let icon = "";
      switch (this.activityDetails.interview_mode) {
        case "call":
          icon = '<i class="fas fa-phone-alt mr-1"></i>';
          break;
        case "video":
          icon = '<i class="fas fa-video mr-1 text-primary"></i>';
          break;
        case "in_person":
          icon = '<i class="fas fa-user-alt mr-1 text-success"></i>';
          break;
        default:
          icon = "";
      }
      return icon;
    },
  },
  methods: {
    ...mapActions([
      "setCandidateListBackUrl",
      "fetchDocumentByCandidateID",
      "showToast",
      "fetchActivity",
      "downloadDocument",
      "fetchJobBenefitsAdvertDescByJobId",
      "fetchCommentsfor_candidateInterviewer",
      "fetchJobCandidateFrontSheet",
      "addJobCandidateFrontSheet",
      "getCandidateDetails",
    ]),
    handleChangeSelectCustom(name, value) {
      const comment = "comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.review, comment, v);
      Vue.set(this.review, name, v);
      this.payload = {
        ...this.payload,
        [comment]: v,
      };
    },
    openFrontSheet() {
      const { candidate, job } = this.activityDetails;
      const { job_id } = job;
      const { candidate_uid } = candidate;
      this.fetchJobCandidateFrontSheet({
        job_id,
        candidate_uid,
        order_by: "-job_candidate_front_sheet_id",
      }).then(async (res) => {
        if (res?.data) {
          await this.getCandidateDetails(candidate_uid);
          this.frontSheetModal.job = job;
          this.frontSheetModal.candidate = this.candidateProfile;
          this.frontSheetModal.frontSheetInfo = res?.data?.length
            ? res?.data[0]
            : null;
          this.frontSheetModal.isShowPopup = true;
          this.frontSheetModal.comments = res?.data || [];
          this.$store.commit("SET_REVIEWED_CANDIDATEID", candidate_uid);
        } else {
          this.showToast({
            message: "Error while fetching Frontsheet",
            class: "bg-danger text-white",
          });
        }
      });
    },
    frontSheetModalCallBack(action, payload) {
      if (action && payload) {
        this.addJobCandidateFrontSheet(payload).then((res) => {
          this.frontSheetModal.isShowPopup = false;
        });
      } else {
        this.frontSheetModal.isShowPopup = false;
      }
    },
    convert2Sentence(data) {
      return _.startCase(data);
    },
    removeSlot(slot) {
      const { key } = slot;
      Vue.delete(this.slotTime, key);
      return;
    },
    Object2Arr(obj) {
      const keys = Object.keys(obj);
      return keys.map((key) => {
        const item = obj[key];
        return {
          key,
          ...item,
        };
      });
    },
    dateClick(cell, events) {
      const { formattedDate } = cell;
      if (m(formattedDate) >= m().startOf("day")) {
        this.selectedDate = formattedDate;
        this.selectedDateEvents = events;
      }
      this.$store.commit("RESET_INTERVIEW_SLOTS");
      this.$store.commit("RESET_INTERVIEW_COMMENTS");
    },
    handleTimeSlot(name, value) {
      Vue.set(this.slotTime, name, value);
      switch (name) {
        case "slot1": {
          this.slotTab = 1;
          break;
        }
        case "slot2": {
          this.slotTab = 2;
          break;
        }
      }
    },
    navToJobDetails(job_id) {
      this.$store.commit("SET_REVIEWED_JOBID", job_id);
      // let fullPath = this.$route.fullPath;
      // this.$store.commit("SET_JOB_LIST_BACK_URL", fullPath);
      // this.$store.commit("SET_INTERVIEW_SLOTS", this.slotTime);
      // this.$store.commit("SET_INTERVIEW_COMMENTS", this.review?.comments);
      // this.$router.push({ path: `/job-list-preview/${job_id}` });
      this.$emit("openJobInfoModal", job_id);
    },
    navToActivityList() {
      this.$store.commit("SET_ACTIVE_TAB", 0);
      this.$router.push({ path: `/list-activity` });
    },
    changeActiveTab(tab) {
      this.activeTab = tab;
    },
    openJobAdvert(job_id) {
      this.$store.commit("SET_REVIEWED_JOBID", job_id);
      this.fetchJobBenefitsAdvertDescByJobId({
        job_id,
        customer_id: this.getCustomerId,
      }).then((res) => {
        this.advertModal.isShowPopup = true;
      });
    },
    advertModalCallBack() {
      this.advertModal.isShowPopup = false;
    },
    handleInput(name, value) {
      Vue.set(this.review, name, value);
      Vue.set(this.payload, name, value);
    },
    navToCandidateDetails(candidate_uid) {
      this.$store.commit("SET_REVIEWED_CANDIDATEID", candidate_uid);
      // this.setCandidateListBackUrl(this.$route.fullPath);
      // this.$store.commit("SET_INTERVIEW_SLOTS", this.slotTime);
      // this.$store.commit("SET_INTERVIEW_COMMENTS", this.review?.comments);
      // this.$router.push({ path: `/candidate/${candidate_uid}` });
      this.$emit("openCandidateInfoModal", candidate_uid);
    },
    async onSubmit(action) {
      const isValid = await this.$refs.reviewCandidate.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      const checkingData = {
        slot2: [
          "available_slot2_date",
          "available_slot2_start",
          "available_slot2_end",
        ],
        slot3: [
          "available_slot3_date",
          "available_slot3_start",
          "available_slot3_end",
        ],
      };
      let hasError = { flag: false, msg: "" };
      const slotKeys = Object.keys(this.slotTime);
      if (!slotKeys.length) {
        hasError.flag = true;
        hasError.msg = "Atleast one slot required";
      }
      const { candidate_uid, job_id } = this.activityDetails;
      if (
        !(
          this.getReviewedCandidateID.includes(candidate_uid) ||
          this.getReviewedJobID.includes(job_id)
        )
      ) {
        hasError.flag = true;
        hasError.msg = "Please view candidate profile";
      }

      if (hasError.flag) {
        this.showToast({
          class: "bg-danger text-white",
          message: `${hasError.msg}`,
        });
      } else {
        let finalPayload = {
          job_id: this.activityDetails?.job_id,
          candidate_uid: this.activityDetails?.candidate_uid,
        };
        let payload = {
          comments: this.review?.comments,
          action_detail_id: this.activityDetails?.action_detail_id,
        };
        if (slotKeys.length === 1) {
          const slotKey = slotKeys[0];
          payload = {
            ...payload,
            available_slot1_date: this.formatDate(this.slotTime[slotKey].start),
            available_slot1_start: this.formatDate(
              this.slotTime[slotKey].start,
              "HH:mm:ss"
            ),
            available_slot1_end: this.formatDate(
              this.slotTime[slotKey].end,
              "HH:mm:ss"
            ),
          };
        } else {
          if (!_.has(this.slotTime, "slot1")) {
            let sKey = [];
            for (let index = 0; index < slotKeys.length; index++) {
              if (index === 0) {
                if (slotKeys[index] === "slot2") {
                  sKey = [...sKey, "slot2"];
                  payload = {
                    ...payload,
                    available_slot1_date: this.formatDate(
                      this.slotTime?.slot2?.start
                    ),
                    available_slot1_start: this.formatDate(
                      this.slotTime?.slot2?.start,
                      "HH:mm:ss"
                    ),
                    available_slot1_end: this.formatDate(
                      this.slotTime?.slot2?.end,
                      "HH:mm:ss"
                    ),
                  };
                } else if (slotKeys[index] === "slot3") {
                  sKey = [...sKey, "slot3"];
                  payload = {
                    ...payload,
                    available_slot1_date: this.formatDate(
                      this.slotTime?.slot3?.start
                    ),
                    available_slot1_start: this.formatDate(
                      this.slotTime?.slot3?.start,
                      "HH:mm:ss"
                    ),
                    available_slot1_end: this.formatDate(
                      this.slotTime?.slot3?.end,
                      "HH:mm:ss"
                    ),
                  };
                }
              } else {
                if (slotKeys[index] === "slot2" && !_.has(sKey, "slot2")) {
                  payload = {
                    ...payload,
                    available_slot2_date: this.formatDate(
                      this.slotTime?.slot2?.start
                    ),
                    available_slot2_start: this.formatDate(
                      this.slotTime?.slot2?.start,
                      "HH:mm:ss"
                    ),
                    available_slot1_end: this.formatDate(
                      this.slotTime?.slot2?.end,
                      "HH:mm:ss"
                    ),
                  };
                } else if (slotKeys[index] === "slot3") {
                  payload = {
                    ...payload,
                    available_slot3_date: this.formatDate(
                      this.slotTime?.slot3?.start
                    ),
                    available_slot3_start: this.formatDate(
                      this.slotTime?.slot3?.start,
                      "HH:mm:ss"
                    ),
                    available_slot3_end: this.formatDate(
                      this.slotTime?.slot3?.end,
                      "HH:mm:ss"
                    ),
                  };
                }
              }
            }
          } else {
            if (_.has(this.slotTime, "slot1")) {
              payload = {
                ...payload,
                available_slot1_date: this.formatDate(
                  this.slotTime?.slot1?.start
                ),
                available_slot1_start: this.formatDate(
                  this.slotTime?.slot1?.start,
                  "HH:mm:ss"
                ),
                available_slot1_end: this.formatDate(
                  this.slotTime?.slot1?.end,
                  "HH:mm:ss"
                ),
              };
            }
            if (_.has(this.slotTime, "slot2")) {
              payload = {
                ...payload,
                available_slot2_date: this.formatDate(
                  this.slotTime?.slot2?.start
                ),
                available_slot2_start: this.formatDate(
                  this.slotTime?.slot2?.start,
                  "HH:mm:ss"
                ),
                available_slot2_end: this.formatDate(
                  this.slotTime?.slot2?.end,
                  "HH:mm:ss"
                ),
              };
            }
            if (_.has(this.slotTime, "slot3")) {
              payload = {
                ...payload,
                available_slot3_date: this.formatDate(
                  this.slotTime?.slot3?.start
                ),
                available_slot3_start: this.formatDate(
                  this.slotTime?.slot3?.start,
                  "HH:mm:ss"
                ),
                available_slot3_end: this.formatDate(
                  this.slotTime?.slot3?.end,
                  "HH:mm:ss"
                ),
              };
            }
          }
        }
        finalPayload = { ...finalPayload, payload };
        this.$emit("changeActivityStatus", finalPayload);
      }
    },
    fileDownload(data) {
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      url=appendAccessToken(url)
      console.log('url: ', url);
      this.downloadDocument({ url, document_name });
    },
    previewCV(data) {
      // let _base_domain = "https://" + window.location.host;
      const link_to_document = generateFullUrlWithToken(data.link);
      if (["PNG", "JPG", "JPEG", "GIF"].includes(data?.document_ext)) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (data?.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      } else if (
        ["TXT", "EPS", "TIF", "TIFF", "RAW"].includes(data?.document_ext)
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.previewModal.isPreview = false;
        this.previewModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" + link_to_document;
        this.previewModal.isPreview = true;
        this.previewModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.previewModal.isPreview = false;
      this.previewModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.review, name, value);
      Vue.set(
        this.payload,
        name,
        value ? m(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    getCandidateName(candidate) {
      return (candidate && candidate.full_name) || "--";
    },
    convert12To24(date) {
      if (date) return m(date, ["h:mm A"]).format("HH:mm:ss");
      return null;
    },
    // formatDate(date) {
    //   if (date) return m(date).format("YYYY-MM-DD");
    //   return null;
    // },
    formatDate(date, pattern = "YYYY-MM-DD") {
      if (date) return m(date).format(pattern);
      return null;
    },
  },
  mounted() {
    const {
      candidate_uid,
      candidate: { customer_uid },
      action_detail_id,
      status_id,
      sub_status_id,
    } = this.activityDetails;
    this.fetchDocumentByCandidateID({ candidate_uid, customer_uid });
    this.slotTime = this.getInterviewCreatedSlots;
    const slotTimeKeys = Object.keys(this.slotTime);
    if (slotTimeKeys.length) {
      const lastKey = slotTimeKeys[slotTimeKeys.length - 1];
      const lastSlot = this.slotTime[lastKey];
      this.selectedDateEvents = this.events.filter((e) => {
        return (
          m(e.start).format("YYYY-MM-DD") ===
          m(lastSlot.start).format("YYYY-MM-DD")
        );
      });
      this.selectedDate = m(lastSlot.start).format("YYYY-MM-DD");
    }
    this.review["comments"] = this.getInterviewComments;
    setTimeout(() => {
      this.$store.commit("RESET_INTERVIEW_SLOTS");
      this.$store.commit("RESET_INTERVIEW_COMMENTS");
    }, 500);
    this.fetchCommentsfor_candidateInterviewer({ status_id, sub_status_id });
  },
  filters: {
    convert2Sentence(data) {
      return _.startCase(data);
    },
    formatTime(date) {
      if (date) return m(date).format("hh:mm A");
      return "--";
    },
    formatDate(data) {
      if (data) return m.utc(data).format("Do MMM YYYY");
      return "--";
    },
    remainingDays(data) {
      if (data) {
        const alertDate = m.utc(data).local();
        const todaysdate = m().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        });
        const days = alertDate.diff(todaysdate, "days");
        return days >= 0
          ? `${days} Day${days > 1 ? "s" : ""}`
          : "Already Expired";
      }
      return "--";
    },
    roles(data) {
      if (data)
        return data.roles
          .filter((v) => v.role_type_id != 7)
          .map(({ role_type }) => _.startCase(role_type))
          .join(", ");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.action-btn {
  width: 110px;
}
.center {
  display: flex;
  justify-content: center;
}
.mobile-view {
  @media (min-width: 900px) {
    display: none;
  }
}
.desktop-view {
  @media (max-width: 900px) {
    display: none;
  }
}
.item-container {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
}
.item {
  display: inline-table;
  padding: 2px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #333;
  background: #f0f0f0;
  font-size: 14px;
}
.clear {
  color: #e66363 !important;
  cursor: pointer;
}
.item-label {
  padding-right: 5px;
  white-space: pre-line;
  user-select: none;
  display: table-cell;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
}
</style>
